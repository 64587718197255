<template>
  <div class="welcome-container">
    <div class="search">
      <div>
        <span style="color: #606266; font-size: 14px;margin-right: 12px;">赛季</span>
        <el-select
            placeholder="请选择"
            style="width: 240px;margin-right: 12px;"
            v-model="season"
        >
            <el-option
                v-for="item in dict_season"
                :key="item.dictCode"
                :label="item.dictName"
                :value="item.dictValue"
            ></el-option>
        </el-select>
        <el-button type="primary" @click="getData">查询</el-button>
      </div>
      <el-button type="primary" plain @click="showExport">导出数据</el-button>
    </div>
    <div class="national">
      <div class="panel" :class="'panel'+i" :style="{'cursor': item.path ? 'pointer' : ''}" v-for="(item,i) of nationalData" :key="i" @click="handleTo(item.path)">
        <div>
          <div class="title">
            {{ item.title }}
            <el-tooltip v-if="item.tips" class="item" effect="light" popper-class="pop" content="统计范围为非驳回考量" placement="right-start">
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
          <div class="num">{{ item.num }}</div>
        </div>
        <img :class="'img'+(i+1)" :src="require('../../../../../../assets/img/home/' + (i+1 )+ '.png')" alt="">
        <!-- <el-progress
          type="dashboard"
          :percentage="item.percentage"
          :color="item.color"
          stroke-linecap="square"
          :width="80"
          :stroke-width="10"></el-progress> -->
      </div>
    </div>
    <el-row :gutter="20">
      <el-col :span="bigDistrict.bigDistrictName === '全国' ? 24 : 12" v-for="(bigDistrict, index) of list" :key="index">
        <div class="mycard" v-if="bigDistrict.bigDistrictName !== '-'">
          <!-- <el-row class="title_row">
            <el-col>
              <span class="title_class"></span>
            </el-col>
          </el-row> -->
          <div class="title222">{{bigDistrict.bigDistrictName}}</div>
          <div class="mycard-h2">各赛区考量</div>
          <div class="table">
            <div class="tr tr1">
              <div class="td">赛区</div>
              <div class="td">考量</div>
              <div class="td">已申请</div>
              <div class="td">已注册</div>
            </div>
            <div class="tr" v-for="(item, j) of bigDistrict.data" :key="j">
              <div class="td td2">{{ item.districtName }}</div>
              <div class="td td1">{{ item.totalNum }}</div>
              <div class="td td1">{{ item.applyNum }}</div>
              <div class="td td1">{{ item.registerNum }}</div>
            </div>
          </div>
          <div class="mycard-h2">各赛区各组别考量</div>
          <div class="echarts" :id="'mychart' + index"></div>
      </div>
      </el-col>
    </el-row>
    <el-dialog
      title="导出数据"
      :visible.sync="visible"
      :before-close="handleClose"
      width="30%">
      <el-form
        label-width="80px"
        ref="formRef"
        :rules="formRules"
        :model="form"
      >
        <el-form-item label="赛季" prop="season">
          <el-select
            placeholder="默认当前赛季"
            style="width: 100%"
            v-model="form.season"
          >
            <el-option
              v-for="item in dict_season"
              :key="item.dictValue"
              :label="item.dictName"
              :value="item.dictValue"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="赛区" prop="districtIdList">
          <el-select
            placeholder="请选择"
            style="width: 100%"
            multiple
            v-model="form.districtIdList"
            @change="districtClicked($event)"
            clearable
          >
            <el-option
              v-for="item in dict_district"
              :key="item.id"
              :label="item.districtName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学校" prop="schoolIdList">
          <el-select
            placeholder="请选择"
            style="width: 100%"
            multiple
            v-model="form.schoolIdList"
            clearable
          >
            <el-option
              v-for="item in dict_ApplySchool"
              :key="item.id"
              :label="item.schoolName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!--   写死       0 申请 1 通过/待寄出 2 未通过 3 已寄出  -->
        <el-form-item label="审核类型" prop="applyStatus">
          <el-select
            placeholder="请选择"
            multiple
            style="width: 100%"
            v-model="form.applyStatus"
          >
            <el-option label="未审核" value="0"></el-option>
            <el-option label="审核通过，待寄出" value="1"></el-option>
            <el-option label="审核未通过" value="2"></el-option>
            <el-option label="已寄出" value="3"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeExport">取 消</el-button>
        <el-button type="primary" @click="exportFn">导 出</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { queryDistrictPage, getApplySchool } from '@/http/api'
export default {
  data () {
    return {
      season: '2024', // this.$chnEngStatusCode.defaultSeason,
      dict_season: this.$userInfo.getDataList(this, 't_season').then(value => { this.dict_season = value }),
      dict_district: [],
      dict_ApplySchool: [],
      nationalData: [
        {
          title: '总考量',
          tips: true,
          key: 'totalCurrentNumber',
          num: 0,
          percentage: 0,
          color: ''
        },
        {
          title: '本赛季申请考量',
          key: 'applyNum',
          num: 0,
          percentage: 0,
          color: '#FF8001',
          path: '/individual_racing_tool/individual_school_considerations'
        },
        {
          title: '已注册考量',
          key: 'registerNum',
          num: 0,
          percentage: 0,
          color: '#4EC61D'
        }
      ],
      list: [],
      // 柱状图设置
      barSetting: {
        type: 'bar',
        label: {
          show: true,
          position: 'top',
          textStyle: {
            fontSize: '12px',
            color: '#999'
          }
        },
        barWidth: 20
      },
      visible: false,
      form: {
        season: this.$chnEngStatusCode.defaultSeason,
        districtIdList: [],
        schoolIdList: [],
        applyStatus: []
      },
      formRules: {
        season: [{ required: true, message: '请选择', trigger: 'change' }],
        districtIdList: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolIdList: [{ required: true, message: '请选择', trigger: 'change' }],
        applyStatus: [{ required: true, message: '请选择', trigger: 'change' }]
      }
    }
  },
  created () {
    this.getData()
  },
  methods: {
    getData () {
      this.$http.get('paper/statistics', { params: { season: this.season } }).then(res => {
        if (res.code === 200) {
          const totalCurrentNumber = res.data.totalCurrentNumber
          this.nationalData.forEach(item => {
            item.num = res.data[item.key]
            item.percentage = totalCurrentNumber > 0 ? Number(((item.num / totalCurrentNumber) * 100).toFixed(2)) : 0
          })
          this.list = Object.keys(res.data.considerationList).map(key => {
            return {
              bigDistrictName: key,
              data: res.data.considerationList[key]
            }
          })
          this.list.forEach((bigItem, index) => {
            const seriesData = bigItem.data.map(item => {
              const obj = {
                name: item.districtName,
                data: this.formatData(item)
              }
              return { ...obj, ...this.barSetting }
            })
            const legendData = bigItem.data.map(a => a.districtName)
            console.log(legendData, seriesData)
            setTimeout(() => {
              this.initChart(index, legendData, seriesData)
            })
          })
          console.log(this.list)
        }
      })
    },
    handleClose () {
      this.$refs.formRef.resetFields()
      this.visible = false
    },
    formatData (data) {
      return data.gradeList.map(item => item.number)
    },
    /**
     * 绘制柱状图
     * @param {*} index 对应循环中index，用于id
     * @param {*} legendData 图例赛区数组，例如：['天津', '北京']
     * @param {*} seriesData y轴数据[Array],以赛区为单位
     */
    initChart (index, legendData, seriesData) {
      const myChart = echarts.init(document.getElementById('mychart' + index))
      const option = {
        legend: {
          data: legendData, // 图例名称
          left: 'center',
          itemWidth: 20,
          itemHeight: 12, // 修改icon图形大小
          orient: 'horizontal',
          textStyle: { // 图例文字的样式
            color: '#a1a1a1', // 图例文字颜色
            fontSize: 12 // 图例文字大小
          }
        },
        tooltip: {},
        color: ['#409eff', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        xAxis: { type: 'category', data: ['小初组', '小中组', '小高组', '初中组'] },
        yAxis: {
          minInterval: 1 // 最小刻度是1（y轴人数不能显示小数）
        },
        series: seriesData
      }
      myChart.setOption(option)
      window.addEventListener('resize', () => {
        myChart.resize()
      })
    },
    handleTo (path) {
      if (path) {
        this.$router.push(path)
      }
    },
    // 获取赛区筛选数据
    getDistrictList () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 赛区点击事件
    districtClicked (e) {
      this.dict_ApplySchool = []
      console.log('e', e)
      getApplySchool({ season: this.form.season, districtIdList: e }).then((res) => {
        this.dict_ApplySchool = res.data
      }).catch((err) => {
        console.log('err', err)
      })
    },
    getApplySchool () {

    },
    showExport () {
      this.getDistrictList()
      this.visible = true
    },
    closeExport () {
      this.visible = false
      this.$refs.formRef.resetFields()
    },
    exportFn () {
      this.$refs.formRef.validate(async valid => {
        if (!valid) return
        const str0 = `season=${this.form.season}`
        const str1 = this.form.districtIdList.map(id => {
          return `districtIdList=${id}`
        }).join('&')
        const str2 = this.form.applyStatus.map(id => {
          return `applyStatus=${id}`
        }).join('&')
        const str3 = this.form.schoolIdList.map(id => {
          return `schoolIdList=${id}`
        }).join('&')
        const downLoadUrl = `${this.$env.baseIP}paper/exportStatistics?${str0}&${str1}&${str2}&${str3}`
        this.$downLoadFile.downLoadFileWithFile(this, downLoadUrl, '赛区考量-导出')
      })
    }
  }
}
</script>
<style scoped lang="less">
.welcome-container {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.mycard{
  background-color: #fff;
  border-radius: 6px;
  padding-top: 20px;
  margin-bottom: 20px;
  .title_row{
    margin-left: 20px;
  }
}
.national{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .panel{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 20px;
    background-color: #fff;
    flex: 1;
    padding: 16px 24px;
    padding-left: 40px;
    font-size: 14px;
    border-radius: 6px;
    &:last-child{
      margin-right: 0;
    }
    .title{
      color: #a1a1a1;
    }
    .num{
      font-size: 32px;
      font-family: hzgb;
      margin-top: 10px;
    }
    .img1{
      height: 40px;
    }
    .img2{
      height: 50px;
    }
    .img3{
      height: 34px;
    }
  }
  .panel0{
    border-left: 6px solid #409eff;
  }
  .panel1{
    border-left: 6px solid #FF8001;
  }
  .panel2{
    border-left: 6px solid #4EC61D;
  }
}
.search{
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.echarts{
  width: 100%;
  height: 300px;
}
.table{
  // width: 100%;
  border-top: 1px solid #dcdfe6;
  display: flex;
  // align-items: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 40px;
  font-size: 14px;
  color: #606266;
  .tr{
    flex: 1;
    width: 100%;
  }
  .td{
    border-bottom: 1px solid #dcdfe6;
    padding: 0 12px;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }
  .td1{
    color: #000;
    font-size: 16px;
    font-weight: bold;
  }
  .td2{
    color: #409eff;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .tr1 .td{
    text-align: left;
  }
}
.mycard-h2{
  color: #000;
  font-size: 14px;
  text-align: center;
  margin-bottom: 12px;
}
.title222{
  display: inline-block;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
  padding-left: 4px;
  padding-right: 20px;
  padding-bottom: 2px;
  border-bottom: 2px solid #409eff;
  font-style: italic;
}
.el-icon-question{
  color: #409eff;
  font-size: 18px;
}
</style>
